
import {ApaTransactionFormType, ApaTransactionType} from '@models/charters/charter-apa.model';
import {ApaOperation} from '@models/directories';

const translationKey = 'charter.apa';

export const APA_OPERATIONS: ApaOperation[] = [
  {
    id: ApaTransactionType.APA_TYPE_EXPENSE,
    name: `${translationKey}.expense`,
    formType: ApaTransactionFormType.TRANSACTION,
  },
  {
    id: ApaTransactionType.APA_TYPE_INCOME,
    name: `${translationKey}.income`,
    formType: ApaTransactionFormType.INCOME_OUTCOME,
  },
  {
    id: ApaTransactionType.APA_TYPE_OUTCOME,
    name: `${translationKey}.outcome`,
    formType: ApaTransactionFormType.INCOME_OUTCOME,
  },
  {
    id: ApaTransactionType.APA_TYPE_CASH_MOVE_BOAT_PETTY_CASH,
    name: `${translationKey}.cashMovement`,
    formType: ApaTransactionFormType.TRANSACTION,
  },
];

export function getTransactionTypesByFormType(formType: ApaTransactionFormType): ApaTransactionType[] {
  const items = APA_OPERATIONS.filter(operation => operation.formType === formType);
  if (!items.length) {
    throw new Error('unknown transaction type');
  }
  return items.map(item => item.id);
}

export function getFormTypeByTransactionType(type: ApaTransactionType): ApaTransactionFormType {
  const item = APA_OPERATIONS.find(operation => operation.id === type);
  if (item) {
    return item.formType;
  }
  throw new Error('unknown apa form  type');
}
