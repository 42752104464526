import {
  CharterCommonType,
  CharterContractStatus,
  CharterContractStatusItem,
  CharterKindType,
  CharterPeriodStatus,
  CharterPeriodStatusItem,
  CharterTypeItem,
  PeriodType,
  PeriodTypeItem,
  SystemCharterType,
} from '@models/charters';

export const CHARTER_TYPE_BADGE: CharterTypeItem[] = [
  new CharterTypeItem(SystemCharterType.Charter, CharterCommonType.Charter, CharterKindType.Charter, 'charter.badges.charter', 'charter-type-charter'),
  new CharterTypeItem(SystemCharterType.Charter, CharterCommonType.OwnersCharter, CharterKindType.OwnersCharter, 'charter.badges.ownersCharter', 'charter-type-owners-charter'),
  new CharterTypeItem(SystemCharterType.Charter, CharterCommonType.AllInclusive, CharterKindType.AllInclusive, 'charter.badges.allInclusive', 'charter-type-all-inclusive'),
  new CharterTypeItem(SystemCharterType.Trip, CharterCommonType.Trip, null, 'charter.badges.trip', 'charter-type-trip'),
];

export const CHARTER_PERIOD_STATUS_BADGE: CharterPeriodStatusItem[] = [
  new CharterPeriodStatusItem(CharterPeriodStatus.PastCharter, 'charter.badges.pastCharter', 'charter-type-past-charter'),
  new CharterPeriodStatusItem(CharterPeriodStatus.Running, 'charter.badges.running', 'charter-type-running'),
  new CharterPeriodStatusItem(CharterPeriodStatus.Upcoming, 'charter.badges.upcoming', 'charter-type-upcoming'),
];

export const CHARTER_CONTRACT_BADGE: CharterContractStatusItem[] = [
  new CharterContractStatusItem(CharterContractStatus.Signed, 'charter.badges.signed', 'charter-contract-signed'),
  new CharterContractStatusItem(CharterContractStatus.inProcess, 'charter.badges.inProcess', 'charter-contract-process'),
  new CharterContractStatusItem(CharterContractStatus.Canceled, 'charter.badges.canceled', 'charter-contract-canceled'),
];

export const CHARTER_PERIOD_TYPE: PeriodTypeItem[] = [
  new PeriodTypeItem(PeriodType.Term, 'charter.overview.table.term', '#432791'),
  new PeriodTypeItem(PeriodType.Daily, 'charter.overview.table.daily', '#00B9FF'),
];
